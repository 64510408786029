import React, { PropsWithChildren, Suspense } from 'react';
import { Alert, Space, Card, Button, Row, Col, Typography } from 'antd';
import styled from 'styled-components';
import moment from 'moment';

import CdrEmptyState from '../../shared/components/CdrEmptyState';
import { CdMasqueradeTag } from '../../shared/components/CdMasqueradeTag';
import cdApp from '../../config';

import gettextCatalog from '@/react/services/I18nService';
import CdrPageLoader from '@/react/shared/components/CdrPageLoader';
import { CdPage } from '@/react/shared/components/cd-page/CdPage';
import { usePaymentsSettingsPage } from '@/react/settings/hooks/usePaymentsSettingsPage';
import {
  CdBankIcon,
  CdExclamationTriangleIcon,
  CdInfoIcon,
  CdOpenInNewWindow,
  CdUserAvatar,
} from '@/react/shared/components/Icons';
const { Text } = Typography;

const SpacedAlert = styled(Alert)`
  .ant-alert-action {
    padding-left: 8px;
  }
`;

export const PaymentsSettingsPage = () => (
  <Suspense fallback={<CdrPageLoader />}>
    <PageInner />
  </Suspense>
);

const PageInner = () => {
  const {
    getAccountLink,
    paymentsData,
    notStripeVerified,
    getSupportArticle,
    usesAccountNumber,
    bankAccountIsRequired,
  } = usePaymentsSettingsPage();

  const title = gettextCatalog.getString('Payment settings');
  const subTitle = gettextCatalog.getString(
    'Provide the necessary information needed to have your monthly contributions sent to your account'
  );

  return (
    <CdPage
      pageHeaderProps={{
        title,
        subTitle,
        extra: [
          cdApp.me.masquerading && (
            <Button
              size="large"
              href={
                cdApp.config.environment === 'production'
                  ? `https://dashboard.stripe.com/connect/accounts/${paymentsData.id}`
                  : `https://dashboard.stripe.com/test/connect/accounts/${paymentsData.id}`
              }
            >
              <Space>
                {gettextCatalog.getString('View in stripe')}
                <CdMasqueradeTag />
              </Space>
            </Button>
          ),
        ],
      }}
    >
      <Space direction="vertical" size="large">
        {notStripeVerified && (
          <SpacedAlert
            message={
              <Space>
                <CdExclamationTriangleIcon
                  type="error"
                  size="3x"
                  style={{ padding: 16 }}
                />
                <Space direction="vertical" size={0}>
                  <Text strong>
                    {gettextCatalog.getString(
                      'More details are needed for verification'
                    )}
                  </Text>
                  {gettextCatalog.getString(
                    'Our payment provider, Stripe requires more details to ensure that the person responsible for the church is indeed whom they say they are. Please provide this data to continue using the ChurchDesk payment services.'
                  )}
                </Space>
              </Space>
            }
            type="error"
            action={
              <Space>
                <Button onClick={getSupportArticle}>
                  {gettextCatalog.getString('Click here to get help')}
                </Button>
                <Button type="primary" onClick={getAccountLink}>
                  {gettextCatalog.getString('Add more details')}
                  <CdOpenInNewWindow />
                </Button>
              </Space>
            }
          />
        )}
        <Card
          title={gettextCatalog.getString('Verification details')}
          extra={
            <Button onClick={getAccountLink}>
              {gettextCatalog.getString('Edit payment details')}
              <CdOpenInNewWindow />
            </Button>
          }
        >
          <Card
            title={
              <Space>
                <CdInfoIcon />
                {gettextCatalog.getString('Church Information')}
              </Space>
            }
            type="inner"
            bordered={false}
          >
            <Row>
              <PaymentsText title={gettextCatalog.getString('Address')}>
                {paymentsData.company?.address?.line1}
              </PaymentsText>
              <PaymentsText title={gettextCatalog.getString('Postal code')}>
                {paymentsData.company?.address?.postal_code}
              </PaymentsText>
              <PaymentsText title={gettextCatalog.getString('City')}>
                {paymentsData.company?.address?.city}
              </PaymentsText>
            </Row>
          </Card>

          <Card
            title={
              <Space>
                <CdUserAvatar />
                {gettextCatalog.getString('Church Representative')}
              </Space>
            }
            type="inner"
            bordered={false}
          >
            <Space direction="vertical" style={{ width: '100%' }}>
              <Row>
                <PaymentsText title={gettextCatalog.getString('First name')}>
                  {paymentsData?.person?.first_name}
                </PaymentsText>
                <PaymentsText title={gettextCatalog.getString('Last name')}>
                  {paymentsData?.person?.last_name}
                </PaymentsText>
              </Row>
              <Row>
                <PaymentsText title={gettextCatalog.getString('Address')}>
                  {paymentsData?.person?.address?.line1}
                </PaymentsText>
                <PaymentsText title={gettextCatalog.getString('Postal code')}>
                  {paymentsData?.person?.address?.postal_code}
                </PaymentsText>
                <PaymentsText title={gettextCatalog.getString('City')}>
                  {paymentsData?.person?.address?.city}
                </PaymentsText>
              </Row>
              <Row>
                <PaymentsText title={gettextCatalog.getString('Date of birth')}>
                  {paymentsData?.person?.dob.day
                    ? moment([
                        paymentsData.person.dob.year,
                        paymentsData.person.dob.month - 1,
                        paymentsData.person.dob.day,
                      ]).format('L')
                    : undefined}
                </PaymentsText>
              </Row>
            </Space>
          </Card>
        </Card>
        <Card title={gettextCatalog.getString('Bank account')}>
          {!bankAccountIsRequired &&
          paymentsData?.external_accounts?.data[0]?.last4 ? (
            <Space direction="vertical">
              <Space direction="vertical">
                {gettextCatalog.getString('IBAN')}
                <Text strong>
                  {gettextCatalog.getString('{{ last4 }} (last four digits)', {
                    last4: paymentsData?.external_accounts?.data[0]?.last4,
                  })}
                </Text>
              </Space>
              <div>
                {gettextCatalog.getString(
                  'Please contact ChurchDesk Support if you need to change your bank account detail.'
                )}
              </div>
            </Space>
          ) : (
            <CdrEmptyState
              EmptyStateIcon={<CdBankIcon />}
              title={gettextCatalog.getString(
                'Add bank account to receive transfers'
              )}
              subtitle={
                usesAccountNumber
                  ? gettextCatalog.getString(
                      'In order for ChurchDesk to transfer your contributions we need you to enter your bank account number. This can be done in Stripe.'
                    )
                  : gettextCatalog.getString(
                      'In order for ChurchDesk to transfer your contributions we need you to enter the IBAN number for your bank account. This can be done in Stripe.'
                    )
              }
            />
          )}
        </Card>
      </Space>
    </CdPage>
  );
};

const PaymentsText = (props: PropsWithChildren & { title: string }) => (
  <Col sm={24} md={6}>
    <Space direction="vertical" style={{ width: '100%' }} size={0}>
      {props.title}
      <Text type={props.children ? undefined : 'danger'} strong>
        {props.children || gettextCatalog.getString('Info required')}
      </Text>
    </Space>
  </Col>
);
