import { Col, Select, Space, Typography } from 'antd';
import React from 'react';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { orderBy } from 'lodash';

import { gettextCatalog } from '../../../services/I18nService';

import { COLOR_INDEX } from '@/app/ResourceColors';
import { CdResourceColor } from '@/react/shared/components/Icons';
import { CategoryType } from '@/react/shared/models/category';
import { CategoryByTypeQuery } from '@/react/shared/services/CategoryService';

const { Option } = Select;

export const CdEventCategorySelect = ({
  placeholder,
  value,
  onChange,
  mode,
  type,
  showSecondaryText = true,
  disabled,
}: {
  placeholder: string;
  value?: any;
  onChange?: any;
  mode?: any;
  type?: any;
  showSecondaryText?: boolean;
  disabled?: boolean;
}) => {
  const categories = useRecoilValue(
    CategoryByTypeQuery(type || CategoryType.event)
  );
  const selectCategoriesData = useMemo(
    () =>
      orderBy(categories || [], [(category) => category.name.toLowerCase()])
        .map(({ id, name, color, registerAttendance }) => ({
          value: id,
          label: name,
          color,
          trackingAttendance: registerAttendance,
        }))
        .map((item) => (
          <Option value={item.value} label={item.label} key={item.value}>
            <Space>
              <CdResourceColor
                style={{
                  color: COLOR_INDEX[item.color],
                }}
              />
              <Typography.Text>{item.label}</Typography.Text>
              {showSecondaryText && (
                <Col flex="auto" style={{ textAlign: 'right' }}>
                  <Typography.Text italic type="secondary">
                    {item.trackingAttendance
                      ? gettextCatalog.getString('Tracking attendance')
                      : undefined}
                  </Typography.Text>
                </Col>
              )}
            </Space>
          </Option>
        )),

    [categories, showSecondaryText]
  );

  return (
    <Select
      value={value}
      onChange={onChange}
      mode={mode}
      placeholder={placeholder}
      filterOption={(input, option) =>
        (option.label as string).toLowerCase().includes(input.toLowerCase())
      }
      allowClear={mode === 'multiple'}
      getPopupContainer={(trigger) => trigger.parentNode}
      disabled={disabled}
      showSearch={true}
    >
      {selectCategoriesData}
    </Select>
  );
};
