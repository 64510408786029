import React, { useEffect } from 'react';

import { createCdDrawer } from '../../shared/components/cd-drawer/CdDrawer';
import { gettextCatalog } from '../../services/I18nService';

import { EditSafeguardingSuspended } from './EditSafeguardngSuspended';
import { SafeguardingForm } from './SafeguardingForm';

export const SafeguardingDrawer = createCdDrawer<{
  isEdit: boolean;
  currentUserId: number;
}>({
  modalName: 'SafeguardingDrawer',
  InnerDrawer: ({ setDrawerProps, isEdit, currentUserId }) => {
    useEffect(() => {
      setDrawerProps({
        title: gettextCatalog.getString('Documents and certificates'),
        width: 1000,
        fallback: <EditSafeguardingSuspended />,
        bodyStyle: { padding: '0 16px' },
      });
    }, [setDrawerProps]);

    return <SafeguardingForm isEdit={isEdit} currentUserId={currentUserId} />;
  },
  initialProps: {
    width: 1000,
  },
});
