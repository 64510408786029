import { Space, Tag, TreeSelect, TreeSelectProps } from 'antd';
import React, { useMemo } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { isEmpty } from 'lodash';

import { CdChurch, CdResourceColor } from '../Icons';

import { COLOR_INDEX } from '@/app/ResourceColors';
import { Resource } from '@/react/shared/models/resource';
import { gettextCatalog } from '@/react/services/I18nService';

const FormTreeSelect = styled(TreeSelect)`
  &&&& {
    min-width: 100px;
  }
`;

const FormTreeSelectGlobalStyle = createGlobalStyle`
.select-tree__location-dropdown .ant-select-tree-switcher { display: none}
`;

export function CdLocationSelect({
  placeholder,
  selectedResources,
  onChange,
  value,
  hideNoParishBookedOption = false,
  aggregateSelection = false,
  selectedOrganizationIsMultiChurch = window.cdApp?.showChurchSelector,
  disabled,
  showSearch,
  popupMatchSelectWidth,
  dropdownStyle,
}: {
  placeholder?: string;
  selectedResources: Resource[];
  onChange?: (value: string[]) => void;
  value?: string[];
  hideNoParishBookedOption?: boolean;
  aggregateSelection?: boolean;
  selectedOrganizationIsMultiChurch?: boolean;
  disabled?: boolean;
  showSearch?: boolean;
  popupMatchSelectWidth?: boolean;
  dropdownStyle?: any;
}) {
  const { treeData, resourcesColorMap } = useMemo(() => {
    const resourcesColorMap = {};
    const treeData =
      selectedResources &&
      selectedResources.reduce((resources, resource) => {
        if (!selectedOrganizationIsMultiChurch) {
          if (resource.type === 'church') resources.push({});
          else {
            resourcesColorMap[`resource-${resource.id}`] =
              COLOR_INDEX[resource.color];
            resources.push({
              key: `resource-${resource.id}`,
              value: `resource-${resource.id}`,
              title: resource.name,
              icon: (
                <CdResourceColor
                  style={{
                    color: COLOR_INDEX[resource.color],
                  }}
                />
              ),
            });
          }
        } else if (
          selectedOrganizationIsMultiChurch &&
          resource.type === 'church'
        ) {
          const children =
            resource.resources?.map((childResource) => {
              resourcesColorMap[`resource-${childResource.id}`] =
                COLOR_INDEX[childResource.color];
              return {
                key: `resource-${childResource.id}`,
                value: `resource-${childResource.id}`,
                title: childResource.name,
                parent: resource.id,
                icon: (
                  <CdResourceColor
                    style={{
                      color: COLOR_INDEX[childResource.color],
                    }}
                  />
                ),
              };
            }) || [];
          !hideNoParishBookedOption &&
            children.push({
              key: `church-${resource.id}`,
              value: `church-${resource.id}`,
              title: gettextCatalog.getString('No parish resource booked'),
              parent: null,
              icon: null,
            });
          resources.push({
            key: `parent-${resource.id}`,
            value: `parent-${resource.id}`,
            title: resource.name,
            children,
            icon: <CdChurch />,
          });
        }
        return resources.filter((item) => !isEmpty(item));
      }, []);
    if (selectedOrganizationIsMultiChurch) {
      treeData.push({
        key: 'no-church',
        value: 'no-church',
        title: gettextCatalog.getString('Resources without a parish'),
        children: selectedResources
          .filter((resource) => !resource.parentResourceId && !resource.type)
          .map((childResource) => {
            resourcesColorMap[`resource-${childResource.id}`] =
              COLOR_INDEX[childResource.color];
            return {
              key: `resource-${childResource.id}`,
              value: `resource-${childResource.id}`,
              title: childResource.name,
              icon: (
                <CdResourceColor
                  style={{
                    color: COLOR_INDEX[childResource.color],
                  }}
                />
              ),
            };
          }),
      });
    }
    return { treeData, resourcesColorMap };
  }, [
    selectedResources,
    selectedOrganizationIsMultiChurch,
    hideNoParishBookedOption,
  ]);

  const tProps = {
    treeData,
    switcherIcon: '',
    suffixIcon: null,
    treeCheckable: true,
    showCheckedStrategy: TreeSelect.SHOW_ALL,
    placeholder: placeholder || gettextCatalog.getString('Location'),
    treeDefaultExpandAll: true,
    popupMatchSelectWidth: popupMatchSelectWidth || false,
    showSearch: showSearch || false,
    allowClear: true,
    treeIcon: true,
    popupClassName: 'select-tree__location-dropdown',
    disabled: disabled,
    dropdownStyle: dropdownStyle,
  } as TreeSelectProps;

  if (aggregateSelection) {
    tProps.maxTagCount = 0;
    tProps.maxTagPlaceholder = (selectedItems) => {
      const itemsToCount = selectedItems.filter(
        (item) =>
          (item.value as string).includes('resource-') ||
          (item.value as string).includes('church-')
      );
      return gettextCatalog.getPlural(
        itemsToCount.length,
        '1 location',
        '{{ $count }} locations',
        {
          $count: itemsToCount.length,
        }
      );
    };
  } else {
    tProps.tagRender = (props) => {
      if (props.value.includes('resource-')) {
        return (
          <Tag closable={!disabled} onClose={props.onClose}>
            <Space>
              <CdResourceColor
                style={{
                  color: resourcesColorMap[props.value],
                }}
              />
              {props.label}
            </Space>
          </Tag>
        );
      }
    };
  }
  return (
    <>
      <FormTreeSelect
        {...tProps}
        treeNodeFilterProp="title"
        onChange={onChange}
        value={value}
        getPopupContainer={(trigger) => trigger.parentNode}
      />
      <FormTreeSelectGlobalStyle />
    </>
  );
}

export const retrieveSelectedChurchesAndResource = (selection: any) => ({
  churches: (selection || [])
    .filter((church) => church.includes('church-'))
    .map((value) => value.replace('church-', '')),
  resources: (selection || [])
    .filter((resource) => resource.includes('resource-'))
    .map((value) => value.replace('resource-', '')),
});
