'use strict';

angular.module('cdApp').config([
  '$stateProvider',
  ($stateProvider) => {
    'ngInject';

    // Safeguarding proxy state
    $stateProvider
      .state('app.private.safeguarding', {
        abstract: true,
        url: '/safeguarding',
        template: `
          <cd-app-nav class="app-nav"></cd-app-nav>
          <div class="app-view" ui-view></div>
        `,
      })
      .state('app.private.safeguarding.default', {
        url: '?target&filter',
        params: {
          errorCode: null,
        },
        component: 'safeguardingTemplate',
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Safeguarding');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
          errorCode: ['$stateParams', ($stateParams) => $stateParams.errorCode],
        },
        onEnter: [
          '$state',
          'errorCode',
          '$stateParams',
          function ($state, errorCode, $stateParams) {
            if (!errorCode) {
              return $state.target('app.private.safeguarding.list', {
                filter: $stateParams.filter,
              });
            }
          },
        ],
      })
      .state('app.private.safeguarding.list', {
        url: '/list/:filter?{status}',
        component: 'cdSafeguarding',
        params: {
          errorCode: null,
          filter: {
            type: 'string',
            value: 'all',
          },
          status: {
            type: 'string',
            value: ['active', 'createdWithoutLogin'],
            array: true,
            squash: true,
          },
        },
        resolve: {
          $title: [
            'gettext',
            'gettextCatalog',
            'routingHelpers',
            function (gettext, gettextCatalog, routingHelpers) {
              const base = gettext('Safeguarding');
              return {
                base,
                rendered: routingHelpers.buildTitle(
                  gettextCatalog.getString(base)
                ),
              };
            },
          ],
          errorCode: ['$stateParams', ($stateParams) => $stateParams.errorCode],
        },
      });
  },
]);
