import { Form } from 'antd';
import styled from 'styled-components';
import React from 'react';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
import CountryPhoneInput, { ConfigProvider } from 'antd-country-phone-input';

import { gettextCatalog } from '../../services/I18nService';

import cdApp from '@/react/config';
import { getCountryList } from '@/react/services/locale/country.service';

const StyledComponent = styled.div`
  .antd-country-phone-input {
    height: 32px;
    border-radius: 5px;
    border: 1px solid #e6e6e6;
    margin-bottom: 0px;

    .ant-input {
      font-size: 16px;
      line-height: 18.4px;
      letter-spacing: normal;
    }

    .ant-select-selection-item {
      display: flex;
      flex-direction: row;
    }
  }
`;

const AntdInputPhone = () => {
  const phoneCountryList = getCountryList();
  const country = cdApp.organization.countryIso2;

  return (
    <ConfigProvider
      locale={phoneCountryList}
      areaMapper={(area) => ({
        ...area,
        emoji: (
          <div
            style={{ marginRight: '5px', alignSelf: 'center' }}
            className={`iti-flag ${area.short.toLowerCase()}`}
          />
        ),
      })}
    >
      <StyledComponent>
        <Form.Item
          name="phone"
          label={gettextCatalog.getString('Phone')}
          rules={[
            {
              required: false,
              validator(
                _,
                value: { phone: string; code: number; short: CountryCode }
              ) {
                try {
                  if (
                    !value?.phone?.length ||
                    isValidPhoneNumber(`+${value.code}${value.phone}`)
                  ) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      new Error(
                        gettextCatalog.getString(
                          'Please input a valid phone number'
                        )
                      )
                    );
                  }
                } catch (e) {
                  // Mute errors
                  return Promise.reject(
                    new Error(
                      gettextCatalog.getString(
                        'Please input a valid phone number'
                      )
                    )
                  );
                }
              },
            },
          ]}
          initialValue={{
            short: country,
          }}
          style={{ marginBottom: '10px' }}
        >
          <CountryPhoneInput />
        </Form.Item>
      </StyledComponent>
    </ConfigProvider>
  );
};

export default AntdInputPhone;
