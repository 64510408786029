import React, { FunctionComponent, Suspense } from 'react';
import { Button } from 'antd';
import { useRecoilValue } from 'recoil';

import { useSafeguardingReport } from '../hooks/useSafeguardingReport';
import { useSafeguarding } from '../hooks/useSafeguarding';

import { SafeguardingList } from './SafeguardingList';

import { CdPage } from '@/react/shared/components/cd-page/CdPage';
import AuthorizationService from '@/react/services/AuthorizationService';
import CdrPageLoader from '@/react/shared/components/CdrPageLoader';
import { gettextCatalog } from '@/react/services/I18nService';
import CdrEmptyState from '@/react/shared/components/CdrEmptyState';
import { CdExclamationTriangleIcon } from '@/react/shared/components/Icons';
import { UserHasPermissionAnywhereQuery } from '@/react/user/store/permissions';
import { showCreateUserModal } from '@/react/user/components/CreateUserModal';
import { ProductLandingPageWrapper } from '@/react/product-landingpage/ProductLandingPage';
import { ShowSafeguardingProfileUploaderModal } from '@/react/safeguarding/safeguarding/SafeguardingProfileUploader';

/**
 * This component will render the Safeguarding page for all the organization having de language
 * @returns safeguarding page
 */

const Safeguarding = ({
  filter,
  status,
}: { filter: string; status: string[] }) => {
  const title = gettextCatalog.getString('Safeguarding');
  const subTitle = gettextCatalog.getString(
    'Keep track of your required documents and certificates'
  );
  const { getDownloadTokenAndDownloadFile } = useSafeguardingReport();
  const { canEdit } = useSafeguarding();

  const safeguardingPackageEnabled =
    AuthorizationService.hasPackage('safeguarding');

  const canAccessSafeguarding = useRecoilValue(
    UserHasPermissionAnywhereQuery('safeguarding.access')
  );
  const isOrganizationAdmin =
    AuthorizationService.hasPermission('canAdministerUsers');

  const renderSafeguardingList = () => {
    if (safeguardingPackageEnabled) {
      if (canAccessSafeguarding) {
        return <SafeguardingList filter={filter} status={status} />;
      } else {
        return (
          <CdrEmptyState
            title={gettextCatalog.getString(
              'You do not have access to Safeguarding.'
            )}
            subtitle={gettextCatalog.getString(
              'Ask your organisation administrator to grant access.'
            )}
            EmptyStateIcon={<CdExclamationTriangleIcon />}
          />
        );
      }
    } else {
      return (
        <ProductLandingPageWrapper
          $stateParams={{ packageName: 'safeguarding' }}
        />
      );
    }
  };

  return (
    <CdPage
      pageHeaderProps={{
        title,
        subTitle,
        extra: [
          <Button
            key="safeguardingNewUserModal"
            type="primary"
            disabled={!canAccessSafeguarding || !safeguardingPackageEnabled}
            onClick={() => showCreateUserModal({})}
          >
            {gettextCatalog.getString('Add new user')}
          </Button>,
          <Button
            key="safeguardingExport"
            type="default"
            disabled={
              !canAccessSafeguarding || !safeguardingPackageEnabled || !canEdit
            }
            onClick={getDownloadTokenAndDownloadFile}
          >
            {gettextCatalog.getString('Export')}
          </Button>,
          <Button
            key="safeguardingUserBulkUpload"
            type="default"
            disabled={!isOrganizationAdmin || !safeguardingPackageEnabled}
            onClick={() => ShowSafeguardingProfileUploaderModal()}
          >
            {gettextCatalog.getString('Import users')}
          </Button>,
        ],
      }}
    >
      {renderSafeguardingList()}
    </CdPage>
  );
};
interface SafeguardingWrapperProps {
  $stateParams: {
    filter: string;
    status: string[];
  };
}

export const SafeguardingWrapper: FunctionComponent<SafeguardingWrapperProps> =
  ({ $stateParams }) => (
    <Suspense fallback={<CdrPageLoader />}>
      <Safeguarding
        filter={$stateParams?.filter}
        status={$stateParams?.status}
      />
    </Suspense>
  );
