import React, { useEffect } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';

import { gettextCatalog } from '../../services/I18nService';
import {
  documentTypes,
  documentWhereaboutsTypes,
  fileUploadTypes,
  safeguardingCourses,
} from '../types/SafeguardingTypes';
import {
  getFileUploadModalTitle,
  safeguardingFormSelect,
} from '../common/Helpers';
import { useSafeguardingFileUpload } from '../hooks/useSafeguardingFileUpload';
import UploadFile from '../../people/components/UploadFile';
import { useSafeguardingAddRecord } from '../hooks/useSafeguardingAddRecord';
import {
  createCdModal,
  ModalType,
} from '../../shared/components/cd-modal-new/CdModal2';

import { formatDecimalToTime, getDecimalSeparator } from '@/react/shared/utils';

export const showSafeguardingFileUploadModal = createCdModal<{
  currentUserId: number;
  fileType: string;
}>(({ setModalProps, currentUserId, fileType }) => {
  const { uploadFile, userOptions } = useSafeguardingFileUpload();

  const { fileUploadForm, onFileUploadFormFinish, fileUploadFormInitialValue } =
    useSafeguardingAddRecord(currentUserId, fileType);

  useEffect(() => {
    setModalProps({
      modalType: ModalType.MODAL,
      title: getFileUploadModalTitle(fileType),
      okText: gettextCatalog.getString('Save'),
      onOk: onFileUploadFormFinish,
    });
  }, [fileType, onFileUploadFormFinish, setModalProps]);

  const hours = Form.useWatch('courseDuration', fileUploadForm);

  return (
    <Form
      layout="vertical"
      form={fileUploadForm}
      initialValues={fileUploadFormInitialValue}
    >
      <Row gutter={12}>
        {/* general info for all file upload types */}
        <Col span={12}>
          <Form.Item
            name="reviewedBy"
            label={gettextCatalog.getString('Reviewed by')}
            rules={[
              {
                required: true,
                message: gettextCatalog.getString('This field is required.'),
              },
            ]}
          >
            <Select
              options={userOptions}
              optionFilterProp="label"
              showSearch
              allowClear
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="reviewDate"
            label={gettextCatalog.getString('Date of review')}
            rules={[
              {
                required: true,
                message: gettextCatalog.getString('This field is required.'),
              },
            ]}
          >
            <DatePicker
              placeholder={gettextCatalog.getString('Select review date')}
              style={{ width: '100%' }}
              format={gettextCatalog.getLongDateFormat()}
            />
          </Form.Item>
        </Col>

        {/* When reviewed by is selected as "other", A name of that other person should be provided, however this field can be optional  */}
        <Col span={24}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, nextValues) =>
              prevValues.reviewedBy !== nextValues.reviewedBy
            }
          >
            {({ getFieldValue }) => {
              if (getFieldValue('reviewedBy') === 'other') {
                return (
                  <Form.Item
                    name="thirdPartyReviewName"
                    label={gettextCatalog.getString('Name of reviewer')}
                  >
                    <Input
                      placeholder={gettextCatalog.getString(
                        'Name of the other reviewer'
                      )}
                    />
                  </Form.Item>
                );
              }
            }}
          </Form.Item>
        </Col>

        {/* specific info for file upload type certificateOfConduct- Document whereabouts, Date of follow-up */}
        {fileType === fileUploadTypes.CERTIFICATE_OF_CONDUCT && (
          <Col span={24}>
            <Form.Item
              name="documentWhereabouts"
              label={gettextCatalog.getString('Document status')}
              rules={[
                {
                  required: true,
                  message: gettextCatalog.getString('This field is required.'),
                },
              ]}
            >
              <Select
                placeholder={gettextCatalog.getString('Select document status')}
                options={documentWhereaboutsTypes()}
              ></Select>
            </Form.Item>
          </Col>
        )}
        {fileType === fileUploadTypes.CERTIFICATE_OF_CONDUCT && (
          <Col span={24}>
            <Form.Item
              name="refreshDate"
              label={gettextCatalog.getString('Date of expiration')}
              extra={gettextCatalog.getString(
                'You need to provide a expiration date for certificate of conduct.'
              )}
              rules={[
                {
                  required: true,
                  message: gettextCatalog.getString('This field is required.'),
                },
              ]}
            >
              <DatePicker
                placeholder={gettextCatalog.getString(
                  'Select date of expiration'
                )}
                style={{ width: '100%' }}
                format={gettextCatalog.getLongDateFormat()}
              />
            </Form.Item>
          </Col>
        )}
        {fileType === fileUploadTypes.CERTIFICATE_OF_CONDUCT && (
          <Col span={24}>
            <Form.Item
              name="comments"
              label={gettextCatalog.getString('Notes')}
              extra={gettextCatalog.getString(
                'Please write your notes here. Maximum 250 characters.'
              )}
              rules={[
                {
                  required: true,
                  message: gettextCatalog.getString('This field is required.'),
                },
                {
                  max: 250,
                  message: 'Comment can not exceed 250 characters',
                },
              ]}
            >
              <Input.TextArea
                autoSize
                placeholder={gettextCatalog.getString('Notes')}
              />
            </Form.Item>
          </Col>
        )}
        {/* specific info for file upload type documents - documentType, file  */}
        {fileType === fileUploadTypes.DOCUMENT && (
          <Col span={24}>
            <Form.Item
              name="documentType"
              label={gettextCatalog.getString('Document type')}
              rules={[
                {
                  required: true,
                  message: gettextCatalog.getString('This field is required.'),
                },
              ]}
            >
              <Select
                placeholder={gettextCatalog.getString(
                  'Select type of document'
                )}
                options={documentTypes()}
              />
            </Form.Item>
          </Col>
        )}
        {fileType === fileUploadTypes.DOCUMENT && (
          <Col span={24}>
            <Form.Item
              label={gettextCatalog.getString('Date of expiration')}
              name="refreshDate"
              extra={gettextCatalog.getString(
                'You can leave this blank if expiration date is not required.'
              )}
            >
              <DatePicker
                placeholder={gettextCatalog.getString(
                  'Select date of expiration'
                )}
                style={{ width: '100%' }}
                format={gettextCatalog.getLongDateFormat()}
              />
            </Form.Item>
          </Col>
        )}
        {fileType === fileUploadTypes.DOCUMENT && (
          <Col span={24}>
            <Form.Item
              name="file"
              label={gettextCatalog.getString('Document')}
              rules={[
                {
                  required: true,
                  message: gettextCatalog.getString('This field is required.'),
                },
              ]}
            >
              <UploadFile
                uploadFile={uploadFile}
                maxFileSize={10}
                multiple={false}
                maxCount={1}
                showUploadList={true}
              >
                <Space direction="vertical">
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <Typography.Text>
                    {gettextCatalog.getString(
                      'Click or drag file to this area to upload'
                    )}
                  </Typography.Text>
                </Space>
              </UploadFile>
            </Form.Item>
          </Col>
        )}
        {/* specific info for file upload type courses - Course date, Title, Type, File  */}
        {fileType === fileUploadTypes.COURSE && (
          <Col span={24}>
            <Form.Item
              name="courseDate"
              label={gettextCatalog.getString('Course date')}
              rules={[
                {
                  required: true,
                  message: gettextCatalog.getString('This field is required.'),
                },
              ]}
            >
              <DatePicker
                placeholder={gettextCatalog.getString('Select course date')}
                style={{ width: '100%' }}
                format={gettextCatalog.getLongDateFormat()}
              />
            </Form.Item>
          </Col>
        )}
        {fileType === fileUploadTypes.COURSE && (
          <Col span={24}>
            <Form.Item
              name="courseTitle"
              label={gettextCatalog.getString('Course title')}
              rules={[
                {
                  required: true,
                  message: gettextCatalog.getString('This field is required.'),
                },
              ]}
            >
              <Input placeholder={gettextCatalog.getString('Name of course')} />
            </Form.Item>
          </Col>
        )}
        {fileType === fileUploadTypes.COURSE && (
          <Col span={24}>
            <Form.Item
              name="courseType"
              label={gettextCatalog.getString('Course type')}
              rules={[
                {
                  required: true,
                  message: gettextCatalog.getString('This field is required.'),
                },
              ]}
            >
              <Select
                placeholder={gettextCatalog.getString('Select course type')}
                options={safeguardingCourses().map(safeguardingFormSelect)}
              />
            </Form.Item>
          </Col>
        )}
        {fileType === fileUploadTypes.COURSE && (
          <Col span={24}>
            <Form.Item
              name="courseDuration"
              label={gettextCatalog.getString('Course duration')}
              extra={formatDecimalToTime(hours)}
            >
              <InputNumber
                style={{ width: '100%' }}
                placeholder={gettextCatalog.getString('Decimal hours')}
                precision={2}
                step={0.01}
                decimalSeparator={getDecimalSeparator()}
                min={0.0}
              />
            </Form.Item>
          </Col>
        )}
        {fileType === fileUploadTypes.COURSE && (
          <Col span={24}>
            <Form.Item
              name="refreshDate"
              label={gettextCatalog.getString('Date of expiration')}
              extra={gettextCatalog.getString(
                'You can leave this blank if expiration date is not required.'
              )}
            >
              <DatePicker
                placeholder={gettextCatalog.getString(
                  'Select date of expiration'
                )}
                style={{ width: '100%' }}
                format={gettextCatalog.getLongDateFormat()}
              />
            </Form.Item>
          </Col>
        )}
        {fileType === fileUploadTypes.COURSE && (
          <Col span={24}>
            <Form.Item
              name="file"
              label={gettextCatalog.getString('Course certificate')}
              rules={[
                {
                  required: true,
                  message: gettextCatalog.getString('This field is required.'),
                },
              ]}
            >
              <UploadFile
                uploadFile={uploadFile}
                maxFileSize={10}
                showUploadList={true}
              >
                <Space direction="vertical">
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <Typography.Text>
                    {gettextCatalog.getString(
                      'Click or drag file to this area to upload'
                    )}
                  </Typography.Text>
                </Space>
              </UploadFile>
            </Form.Item>
          </Col>
        )}
      </Row>
    </Form>
  );
});
