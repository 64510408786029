import React, { Suspense } from 'react';

import AuthorizationService from '../services/AuthorizationService';
import { gettextCatalog } from '../services/I18nService';
import { CdFallbackPage } from '../shared/components/CdFallbackPage';

import CdrPageLoader from '@/react/shared/components/CdrPageLoader';
import cdApp from '@/react/config';

export const ProductLandingPage = ({
  packageName,
}: { packageName: string }) => {
  console;
  const language = cdApp.organization.locale?.language;
  // @ts-ignore
  const hasPackage = AuthorizationService.hasPackage(packageName);
  let iframeUrl: string = '';
  switch (packageName) {
    case 'calendar':
      switch (language) {
        case 'da':
          iframeUrl = 'https://churchdesk.com/da/app-calendar/';
          break;
        case 'de':
          iframeUrl = 'https://churchdesk.com/de/app-calendar/';
          break;
        default:
          iframeUrl = 'https://churchdesk.com/en/app-calendar/';
          break;
      }
      break;
    case 'people':
      switch (language) {
        case 'da':
          iframeUrl = 'https://churchdesk.com/da/app-people/';
          break;
        case 'de':
          iframeUrl = 'https://churchdesk.com/de/app-people/';
          break;
        default:
          iframeUrl = 'https://churchdesk.com/en/app-people/';
          break;
      }
      break;
    case 'forms':
      switch (language) {
        case 'da':
          iframeUrl = 'https://churchdesk.com/da/app-forms/';
          break;
        case 'de':
          iframeUrl = 'https://churchdesk.com/de/app-forms/';
          break;
        default:
          iframeUrl = 'https://churchdesk.com/en/app-forms/';
          break;
      }
      break;
    case 'contributions':
      switch (language) {
        case 'da':
          iframeUrl = 'https://churchdesk.com/da/app-payments/';
          break;
        case 'de':
          iframeUrl = 'https://churchdesk.com/de/app-payments/';
          break;
        default:
          iframeUrl = 'https://churchdesk.com/en/app-payments/';
          break;
      }
      break;
    case 'intentions':
      iframeUrl = 'https://churchdesk.com/de/app-intentionen/';
      break;
    case 'homepage':
      switch (language) {
        case 'da':
          iframeUrl = 'https://churchdesk.com/da/app-website/';
          break;
        case 'de':
          iframeUrl = 'https://churchdesk.com/de/app-website/';
          break;
        default:
          iframeUrl = 'https://churchdesk.com/en/app-website/';
          break;
      }
      break;
    case 'safeguarding':
      iframeUrl = 'https://churchdesk.com/de/app-safeguarding/';
      break;
    case 'intranet':
      switch (language) {
        case 'da':
          iframeUrl = 'https://churchdesk.com/da/app-groups/';
          break;
        case 'de':
          iframeUrl = 'https://churchdesk.com/de/app-groups/';
          break;
        default:
          iframeUrl = 'https://churchdesk.com/en/app-groups/';
          break;
      }
      break;
    default:
      break;
  }

  return !hasPackage ? (
    <iframe
      data-testid="productLandingPageIframe"
      src={iframeUrl}
      style={{
        width: '100%',
        height: '100%',
        border: '0px',
      }}
    ></iframe>
  ) : (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <CdFallbackPage
        title={gettextCatalog.getString(
          'You do not have access to {{product}}.',
          {
            product:
              packageName === 'homepage'
                ? 'Website'
                : packageName?.charAt(0).toUpperCase() + packageName?.slice(1),
          }
        )}
        subtitle={gettextCatalog.getString(
          'Please ask your organization administrator to grant you the {{product}} access permission.',
          {
            product:
              packageName === 'homepage'
                ? 'Website'
                : packageName?.charAt(0).toUpperCase() + packageName?.slice(1),
          }
        )}
        hideButton={true}
        hasBody={false}
      />
    </div>
  );
};

export const ProductLandingPageWrapper = ({ $stateParams }) => (
  <Suspense fallback={<CdrPageLoader marginLess />}>
    <ProductLandingPage packageName={$stateParams?.packageName} />
  </Suspense>
);
