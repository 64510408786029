import moment from 'moment';
import React from 'react';
import { Space, Typography } from 'antd';

import MessageListCreateDropdown from '../../message-editor/shared/containers/MessageListCreateDropdown';
import { ColumnType } from '../../../shared/components/cd-table';
import { AnniversaryInterface } from '../../../calendar/types/anniversaryTypes';
import { gettextCatalog } from '../../../services/I18nService';
import { OrderType } from '../../../shared/models/table';

import { getStateUrl } from '@/react/services/StateServiceFactory';
import { CdCheckIcon, CdTime } from '@/react/shared/components/Icons';
import CdTag from '@/react/shared/components/cd-tag/CdTag';
import CdTooltip from '@/react/shared/components/cd-tooltip/CdTooltip';

const { Text } = Typography;
export const anniversaryColumns = () => {
  const columns: ColumnType<AnniversaryInterface>[] = [
    {
      dataIndex: 'fullName',
      key: 'fullName',
      title: gettextCatalog.getString('Name'),
      defaultSortOrder: 'ascend',
      orderType: OrderType.STRING,
      sorter: true,
      render: (value, record) => (
        <a
          key={record.id}
          href={getStateUrl(`app.private.people.contacts.view`, {
            id: record.id,
          })}
        >
          {value}
        </a>
      ),
    },
    {
      dataIndex: ['anniversary', 'label'],
      title: gettextCatalog.getString('Event type'),
      key: 'anniversary.label',
      sorter: true,
      defaultSortOrder: 'ascend',
      orderType: OrderType.STRING,
    },
    {
      dataIndex: ['anniversary', 'date'],
      title: gettextCatalog.getString('Date'),
      render: (date: string) => moment(date).format('LL'),
      key: 'anniversary.date',
      sorter: true,
      orderType: OrderType.DATE,
      defaultSortOrder: 'ascend',
    },
    {
      dataIndex: ['anniversary', 'isSpecial'],
      title: gettextCatalog.getString('Special'),
      key: 'anniversary.isSpecial',
      render: (value, record) => (value ? <CdCheckIcon key={record.id} /> : ''),
    },
    {
      dataIndex: ['anniversary', 'yearSince'],
      title: gettextCatalog.getString('Year since'),
      key: 'anniversary.yearSince',
      sorter: true,
      defaultSortOrder: 'ascend',
      orderType: OrderType.NUMBER,
      render: (value) =>
        gettextCatalog.getPlural(value, '1 year', '{{ count }} years', {
          count: value,
        }),
    },
    {
      dataIndex: 'messages',
      title: gettextCatalog.getString('Message status'),
      key: 'messages',
      width: 200,
      render: (messages) => {
        if (messages?.length) {
          return (
            <Space style={{ flexWrap: 'wrap' }}>
              {messages.map((message) => (
                <MessageStatus key={message.uniqueId} message={message} />
              ))}
            </Space>
          );
        } else {
          return null;
        }
      },
    },
    {
      dataIndex: 'buttons',
      title: '',
      key: 'buttons',
      align: 'right',
      width: 100,
      render: (value, record) => (
        <MessageListCreateDropdown
          buttonLabel={gettextCatalog.getString('Send message')}
          buttonSize="small"
          recipients={[record]}
          buttonType="default"
          visibleInvitation={false}
          visibleNewsletter={false}
        />
      ),
    },
  ];
  return columns;
};

const MessageStatus = ({ message }) => {
  const type = message.peopleMessage.sent ? 'success' : null;
  let tagTitle = '';
  let link = '';
  switch (message.peopleMessage.type) {
    case 'sms':
      tagTitle = gettextCatalog.getString('SMS');
      link = getStateUrl(`app.private.people.messages.view`, {
        id: message.peopleMessage.id,
      });
      break;
    case 'advanced':
      tagTitle = gettextCatalog.getString('Studio');
      link = getStateUrl(`app.private.people.messages.editorV2.view`, {
        type: message.peopleMessage.type,
        messageId: message.peopleMessage.id,
        display: 'view',
      });
      break;
    default:
      tagTitle = gettextCatalog.getString('E-Mail');
      link = getStateUrl(`app.private.people.messages.editorV2.view`, {
        type: message.peopleMessage.type,
        messageId: message.peopleMessage.id,
        display: 'view',
      });
      break;
  }
  const TooltipTitle = () => {
    if (message.peopleMessage.sent) {
      return (
        <Space direction="vertical">
          {gettextCatalog.getString('Sent on {{sentDate}}', {
            sentDate: moment(message.peopleMessage.sent).format('l'),
          })}
          <a href={link}>{gettextCatalog.getString('Click here to view.')}</a>
        </Space>
      );
    }
    if (!message.peopleMessage.sent && message.peopleMessage.scheduled) {
      return (
        <Space direction="vertical">
          {gettextCatalog.getString('Scheduled on {{scheduledDate}}', {
            scheduledDate: moment(message.peopleMessage.scheduled).format('l'),
          })}
          <a href={link}>{gettextCatalog.getString('Click here to view.')}</a>
        </Space>
      );
    }
    if (!message.peopleMessage.sent && !message.peopleMessage.scheduled) {
      return (
        <a href={link}>{gettextCatalog.getString('Click here to view.')}</a>
      );
    }
  };
  return (
    <CdTooltip title={<TooltipTitle />}>
      <CdTag type={type}>
        <Space>
          {type === 'success' && <CdCheckIcon />}
          {tagTitle}
          {!message.peopleMessage.sent && !message.peopleMessage.scheduled && (
            <Text type="secondary">{gettextCatalog.getString('draft')}</Text>
          )}
          {!message.peopleMessage.sent && message.peopleMessage.scheduled && (
            <CdTime type="secondary" />
          )}
        </Space>
      </CdTag>
    </CdTooltip>
  );
};
