import React, { useState, useRef, useEffect } from 'react';
import { useCallback } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import { gettextCatalog } from '../../../../services/I18nService';
import { EmojiPicker } from '../../../../shared/components/EmojiPicker';

import { CdEmoji } from '@/react/shared/components/Icons';
import { ParentType } from '@/react/people/types/message';

/*
  hic sunt dracones
  we are using a jQuery plugin and wrapping it in angular's jqLight,
  using loads of refs and more or less dropping out of the react mental model
  with old school DOM interactions
  https://imperavi.com/assets/pdf/redactor-documentation-2.pdf
*/

function Portal({ target, children }) {
  return target ? createPortal(children, target) : null;
}

export interface RedactorProps {
  onChange?(emoji: any): void;
  value?: string;
  disabled?: boolean;
  imagePluginEnabled?: boolean;
  messagePlaceHolderEnabled?: boolean;
  showFontTypeButton?: boolean;
  showFontColorButton?: boolean;
  showHtmlButton?: boolean;
  showFontFormatButton?: boolean;
  parentType?: ParentType;
}

const RedactorContainer = styled.div`
  &&.redactor-box {
    height: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
  }

  .redactor-styles {
    font-size: 14px;
    font-family: 'Lato', Arial;
    line-height: 17px;
  }

  .redactor-layer {
    flex: 1 1;
    overflow: auto;
    min-height: 91px !important;
  }

  a.re-cdFont {
    border-left: 1px solid #ccc;
  }
  p {
    margin: 13px 0;
  }
`;

export default function Redactor({
  onChange,
  value,
  disabled,
  imagePluginEnabled = true,
  messagePlaceHolderEnabled = true,
  showFontTypeButton = true,
  showFontColorButton = true,
  showHtmlButton = false,
  showFontFormatButton = false,
  parentType = ParentType.MESSAGE,
}: RedactorProps) {
  const supportedRedactorLanguages = ['en', 'da', 'de', 'fr', 'it', 'sv'];
  const editorRef = useRef(null);
  const toolbarRef = useRef(document.createElement('li'));
  const [$redactor, set$Redactor] = useState(null);
  const [initialValue] = useState(value);
  const [caretPosition, setCaretPosition] = useState(0);
  const language = window.parent.cdApp?.organization.locale.language;
  const [editorReady, setEditorReady] = useState<boolean>(false);
  const [hasValueBeenSet, setValueBeenSet] = useState<boolean>(false);
  // Redactor needs a html <p> tag to correctly start and add event listeners.
  // // The following was found to fix known issues and keep the editor working.
  const redactorInitialValueFix = '<p style="margin:0;" class="initial"> </p>';

  const handleRedactorChange = (rawHTML: string) => {
    // Left the console.log since they are very usefull for debugging.
    // console.log({
    //   rawHTML,
    // });
    if (rawHTML !== initialValue && rawHTML !== redactorInitialValueFix) {
      // Redactor adds a new line after each </p> tag, this is a fix for that.
      const newValue = rawHTML.replace(/<\/p>\n/g, '</p>');
      onChange(newValue);
    }
  };

  useEffect(() => {
    if ($redactor) {
      restoreCaretPosition();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Left the console.log since they are very usefull for debugging.
    // console.log({
    //   value,
    //   redactorValue: $redactor && $redactor.code?.get(),
    //   editorReady,
    // });
    if (hasValueBeenSet) {
      return;
    }
    if (!!value && $redactor?.code?.get() !== value && editorReady) {
      $redactor.code.set(value);
      setValueBeenSet(true);
    } else if (editorReady && !value) {
      $redactor.code.set(redactorInitialValueFix);
      setValueBeenSet(true);
    }
  }, [value, hasValueBeenSet, editorReady, $redactor]);

  const {
    element,
    element: {
      // @ts-ignore - picking stuff of the global context
      fn: { redactor },
    },
    // @ts-ignore - picking stuff of the global context
  } = angular;

  useEffect(() => {
    if (!$redactor) {
      return;
    }
    if (messagePlaceHolderEnabled) {
      const attributesBtn = $redactor.button.add(
        'attributes',
        gettextCatalog.getString('Insert Attribute')
      );
      const attributesDropdown = {
        name: {
          title: gettextCatalog.getString('Full name'),
          func: () => $redactor.insert.text('[name]'),
        },
        name_first: {
          title: gettextCatalog.getString('First name'),
          func: () => $redactor.insert.text('[name:first]'),
        },
        name_last: {
          title: gettextCatalog.getString('Last name'),
          func: () => $redactor.insert.text('[name:last]'),
        },
      };
      if (parentType === ParentType.ANNIVERSARY) {
        // @ts-ignore
        attributesDropdown.label = {
          title: gettextCatalog.getString('Anniversary label'),
          func: () => $redactor.insert.text('[anniversary:label]'),
        };
        // @ts-ignore
        attributesDropdown.date = {
          title: gettextCatalog.getString('Anniversary date'),
          func: () => $redactor.insert.text('[anniversary:date]'),
        };
        // @ts-ignore
        attributesDropdown.yearSince = {
          title: gettextCatalog.getString('Anniversary year since'),
          func: () => $redactor.insert.text('[anniversary:yearSince]'),
        };
        // @ts-ignore
        attributesDropdown.inComingDate = {
          title: gettextCatalog.getString('Anniversary in coming date'),
          func: () => $redactor.insert.text('[anniversary:inComingDate]'),
        };
      }
      $redactor.button.addDropdown(attributesBtn, attributesDropdown);
      $redactor.button.setIcon(attributesBtn, '<i class="fas fa-user"></i>');
    }

    if (showFontTypeButton) {
      const fontBtn = $redactor.button.addBefore(
        'fontsize',
        'cdFont',
        gettextCatalog.getString('Select Font')
      );
      $redactor.button.setIcon(fontBtn, '<i class="fa fa-font"></i>');
      const fontDropdown = {
        georgia: {
          title: 'Georgia',
          func: () =>
            $redactor.inline.format('span', 'style', 'font-family: Georgia'),
        },
        arial: {
          title: 'Arial',
          func: () =>
            $redactor.inline.format('span', 'style', 'font-family: Arial'),
        },
        trebuchet: {
          title: 'Trebuchet MS',
          func: () =>
            $redactor.inline.format(
              'span',
              'style',
              'font-family: Trebuchet MS'
            ),
        },
        timesnewroman: {
          title: 'Times New Roman',
          func: () =>
            $redactor.inline.format(
              'span',
              'style',
              'font-family: Times New Roman'
            ),
        },
        verdana: {
          title: 'Verdana',
          func: () =>
            $redactor.inline.format('span', 'style', 'font-family: Verdana'),
        },
      };
      $redactor.button.addDropdown(fontBtn, fontDropdown);
    }

    const fontSizeBtn = $redactor.button.get('fontsize');
    $redactor.button.setIcon(fontSizeBtn, '<i class="fas fa-text-size"></i>');

    if (!showFontFormatButton) {
      $redactor.button.remove('format');
    }

    const toolbar: HTMLUListElement = $redactor.$toolbar;
    toolbar.append(toolbarRef.current);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$redactor]);
  // horizontalrule has been removed since its buggy if you add it to the end of some text.
  // As an example you cannot paste any text after it.
  const defaultButtons = ['bold', 'italic', 'underline', 'lists'];

  if (showFontFormatButton) {
    defaultButtons.push('format');
  }

  useEffect(() => {
    if (!editorRef.current) {
      return;
    }
    if (editorRef.current?.firstElementChild.tagName === 'TEXTAREA') {
      const plugins = ['fontsize', 'alignment', 'bufferbuttons', 'linkit'];
      if (showFontColorButton) {
        plugins.push('fontcolor');
      }
      if (imagePluginEnabled) {
        plugins.push('imagePicker');
      }
      if (showHtmlButton) {
        plugins.push('source');
      }
      redactor.call(element(editorRef.current?.firstElementChild), {
        callbacks: {
          init: function () {
            set$Redactor(this);
            setEditorReady(true);
          },
          destroy: function () {
            set$Redactor(null);
          },
          change: handleRedactorChange,
        },
        linkValidation: false, // MH: Avoids stripping of https.
        imageResizable: true,
        imagePosition: true,
        removeDataAttr: true,
        convertVideoLinks: false,
        paragraphSize: false,
        linkTooltip: false,
        linkNewTab: true,
        focus: false,
        lang:
          language === 'en-gb' || !supportedRedactorLanguages.includes(language)
            ? 'en'
            : language,
        activeButtons: ['deleted', 'italic', 'bold', 'underline'],
        activeButtonsStates: {
          b: 'bold',
          strong: 'bold',
          i: 'italic',
          em: 'italic',
          del: 'deleted',
          strike: 'deleted',
          u: 'underline',
        },
        pasteBlockTags: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ul', 'ol', 'li'],
        buttons: defaultButtons,
        plugins,
        formatting: ['h2', 'h3', 'h4', 'h5', 'h6', 'p', 'blockquote'],
      });
    }
    return () => {
      if ($redactor) {
        $redactor.core.destroy();
        set$Redactor(null);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorRef.current]);

  const saveCaretPosition = useCallback(() => {
    if (!$redactor) {
      return;
    }
    setCaretPosition($redactor.offset.get());
  }, [$redactor]);

  const restoreCaretPosition = useCallback(() => {
    if (!$redactor) {
      return;
    }
    $redactor.offset.set(caretPosition || 0);
  }, [$redactor, caretPosition]);

  const insertEmoji = useCallback(
    (emoji: any) => {
      if (!$redactor) {
        return;
      }
      restoreCaretPosition();
      $redactor.insert.raw(emoji.native);
      setCaretPosition((caretPosition) => caretPosition + emoji.native.length);
    },
    [restoreCaretPosition, $redactor]
  );

  if (!onChange && !value) {
    return null;
  }

  return (
    (disabled && (
      <div
        style={{
          height: '100%',
          minHeight: '400px',
          border: '1px solid #ccc',
          padding: '8px',
          backgroundColor: '#f5f5f5',
        }}
        dangerouslySetInnerHTML={{ __html: value }}
      ></div>
    )) || (
      <RedactorContainer
        ref={editorRef}
        onBlur={saveCaretPosition}
        style={{ height: '100%' }}
      >
        <textarea onFocus={restoreCaretPosition} onBlur={saveCaretPosition} />
        <Portal target={toolbarRef.current}>
          <EmojiPicker
            buttonSize="middle"
            buttonType="text"
            onSelect={insertEmoji}
            showLabel={false}
          >
            <a className="re-button re-link re-button-icon">
              <CdEmoji />
            </a>
          </EmojiPicker>
        </Portal>
      </RedactorContainer>
    )
  );
}
