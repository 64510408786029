import { Resource } from './resource';

export interface Contact {
  firstName: string;
  lastName: string;
  fullName: string;
  language: string;
  birthday: Date;
  internalNote: string;
  gender: string;
  initials: string;
  picture: {
    id: number;
    uri: string;
    url: string;
  };
  pictureId: number;
  workPhone: string;
  homePhone: string;
  occupation: string;
  revision: number;
  prefix: string;
  civilStatus: string;
  allergy: string;
  dietary: string;
  medicalNote: string;
  nationalIdentificationNumber: string;
  relations: PeopleRelation[];
}
export interface People extends Contact {
  id: number;
  email: string;
  phone: string;
  name: string;
  firstName: string;
  lastName: string;
  roles: number[];
  status: number;
  lastAccess: Date;
  createdAt: Date;
  groups: number[];
  contact: Contact;
  churches: Resource[];
}

export enum PeopleRelationType {
  CHILD = 'child',
  PARENT = 'parent',
  PARTNER = 'partner',
  SIBLING = 'sibling',
  UNSPECIFIED = 'unspecified',
}
export interface PeopleRelation {
  person: {
    personId: number;
  };
  relation: PeopleRelationType;
  relationId?: number;
  isEmailShared?: boolean;
  isPhoneShared?: boolean;
}
