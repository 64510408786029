import {
  useRecoilCallback,
  useRecoilRefresher_UNSTABLE as useRecoilRefresher,
  useRecoilValue,
} from 'recoil';
import { Form } from 'antd';
import { useCallback } from 'react';

import { SafeGuardingDetailsQuery } from '../store/Safeguarding';
import { mainApi } from '../../api';
import NotificationService from '../../services/NotificationService';
import { gettextCatalog } from '../../services/I18nService';
import {
  handleAntFormValidationErrors,
  handleError,
} from '../../services/ErrorHandlingService';
import { HasMultipleChurches } from '../../user/store/user-session';
import { safeguardingInformationInterface } from '../types/SafeguardingTypes';
import cdApp from '../../config';

import { useSafeguarding } from './useSafeguarding';

import { getCountryName } from '@/react/services/locale/country.service';

export const useSafeguardingForm = (currentUserId: number) => {
  const safeguardingData = useRecoilValue(
    SafeGuardingDetailsQuery(currentUserId)
  );
  const refreshSafeguarding = useRecoilRefresher(
    SafeGuardingDetailsQuery(currentUserId)
  );
  const isMultiChurch = useRecoilValue(HasMultipleChurches);

  const { refreshSafeguardingList } = useSafeguarding();
  const [safeguardingForm] = Form.useForm();

  const onSafeguardingInfoSave = useRecoilCallback(
    () =>
      async (safeguardingInfoFormData: safeguardingInformationInterface) => {
        // even if not a multi church, add the parish id in the payload.
        const response = await mainApi.put(
          `/safeguard/users/${currentUserId}/info`,
          {
            ...safeguardingInfoFormData,
            parishIds: isMultiChurch
              ? safeguardingInfoFormData.parishIds
              : [cdApp.organization.churches[0].id],
          }
        );
        if (!response.ok) {
          handleError(response);
          throw response.data;
        }
        refreshSafeguarding();
        refreshSafeguardingList();
        NotificationService.notifySuccess(
          gettextCatalog.getString(
            'Safeguarding information has been saved successfully'
          )
        );
      },
    [currentUserId, isMultiChurch, refreshSafeguarding, refreshSafeguardingList]
  );
  const onSafeguardingInfoSaveHandler = useCallback(async () => {
    await safeguardingForm
      .validateFields()
      .then((value) => {
        onSafeguardingInfoSave(value?.safeguardInfo);
      })
      .catch(handleAntFormValidationErrors);
  }, [onSafeguardingInfoSave, safeguardingForm]);

  const deleteFile = useRecoilCallback(
    () => async (id: number) => {
      const response = await mainApi.delete(`/safeguard/${id}`);
      if (response.ok) {
        refreshSafeguarding();
        refreshSafeguardingList();
        NotificationService.notifySuccess(
          gettextCatalog.getString('The record has been deleted')
        );
        return;
      }
    },
    [refreshSafeguarding, refreshSafeguardingList]
  );
  const getAddressFormat = () => {
    const address = safeguardingData?.address;
    if (address === undefined) {
      return undefined;
    }
    const postCodeAndCity = [address?.postCode, address?.city]
      .filter(Boolean)
      .join(' ');
    return [
      address?.street,
      postCodeAndCity,
      getCountryName(address?.countryIso2),
    ]
      .filter(Boolean)
      .join(', ');
  };
  return {
    safeguardingData,
    safeguardingForm,
    onSafeguardingInfoSaveHandler,
    deleteFile,
    refreshSafeguarding,
    isMultiChurch,
    getAddressFormat,
  };
};
