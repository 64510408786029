'use strict';

import cdApp from '../../../../react/config';

AppNavController.$inject = [
  '$scope',
  '$rootScope',
  '$window',
  '$state',
  'gettextCatalog',
  'Authorization',
  'FeatureToggleService',
];

function AppNavController(
  $scope,
  $rootScope,
  $window,
  $state,
  gettextCatalog,
  Authorization,
  FeatureToggleService
) {
  const $ctrl = this;

  /**
   * Check if the current state matches any of the provided ones
   *
   * @param {Array|String} stateNames - A single state name or an array of state names
   */
  function stateMatchesAny(stateNames) {
    if (_.isArray(stateNames)) {
      return _.some(stateNames, (state) => $state.includes(state));
    }
    return $state.includes(stateNames);
  }

  /**
   * A list of sidebar menu links per module
   */
  const items = {
    people: [
      {
        text: gettextCatalog.getString('Contacts', null, 'Sidebar'),
        classes: 'fa fa-users',
        url: $state.href(
          'app.private.people.contacts.list',
          {},
          { inherit: false }
        ),

        isActive: function () {
          return stateMatchesAny([
            'app.private.people.contacts.list',
            'app.private.people.contacts.view',
            'app.private.people.contacts.import',
          ]);
        },
      },

      {
        text: gettextCatalog.getString('Messages', null, 'Sidebar'),
        classes: 'fa fa-comments',
        url: $state.href('app.private.people.messages.list'),
        isActive: function () {
          return stateMatchesAny([
            'app.private.people.messages.list',
            'app.private.people.messages.create',
            'app.private.people.messages.view',
            'app.private.people.messages.statistics',
            'app.private.people.messages.editorV2',
            'app.private.people.messages.editorV2.view',
            'app.private.people.messages.editorV2.statistics',
          ]);
        },
      },

      {
        text: gettextCatalog.getString('Pastoral notes', null, 'Sidebar'),
        classes: 'fa fa-sticky-note',
        url: $state.href('app.private.people.pastoralNotes'),
        isActive: () => stateMatchesAny('app.private.people.pastoralNotes'),
      },

      {
        text: gettextCatalog.getString('Lists', null, 'Sidebar'),
        classes: 'fa fa-list',
        url: $state.href('app.private.people.lists2'),
        isActive: () => stateMatchesAny('app.private.people.lists2'),
      },

      {
        text: gettextCatalog.getString('Tags', null, 'Sidebar'),
        classes: 'fa fa-tags',
        url: $state.href('app.private.people.tagList'),
        isActive: function () {
          return stateMatchesAny('app.private.people.tagList');
        },
      },

      {
        text: gettextCatalog.getString('Fields', null, 'Sidebar'),
        classes: 'fa fa-sliders-h',
        url: $state.href('app.private.people.fields'),
        isActive() {
          return stateMatchesAny('app.private.people.fields');
        },
        isShownToUser() {
          return Authorization.hasPermission('canAdministerUsers');
        },
      },

      {
        text: gettextCatalog.getString('Consents', null, 'Sidebar'),
        classes: 'fa fa-check-square',
        url: $state.href('app.private.people.consentTypes2'),
        isActive() {
          return stateMatchesAny('app.private.people.consentTypes2');
        },
      },
      {
        text: gettextCatalog.getString('Anniversary', null, 'Sidebar'),
        classes: 'fas fa-heart-square',
        url: $state.href('app.private.people.anniversary'),
        isActive: function () {
          return $state.includes('app.private.people.anniversary');
        },
        isShownToUser: function () {
          return false;
        },
      },
    ],

    contributions: [
      {
        text: gettextCatalog.getString('Projects', null, 'Sidebar'),
        classes: 'fa fa-cubes',
        url: $state.href('app.private.contributions.projects.list'),
        isActive: function () {
          return stateMatchesAny([
            'app.private.contributions.projects.list',
            'app.private.contributions.projects.view',
            'app.private.contributions.projects.create',
            'app.private.contributions.projects.edit',
          ]);
        },
      },

      {
        text: gettextCatalog.getString('Contributions', null, 'Sidebar'),
        classes: 'far fa-money-bill-alt',
        url: $state.href('app.private.contributions.contributionList'),
        isActive: function () {
          return stateMatchesAny('app.private.contributions.contributionList');
        },
      },

      {
        text: gettextCatalog.getString('Transfers', null, 'Sidebar'),
        classes: 'fa fa-exchange',
        url: $state.href('app.private.contributions.transferList'),
        isActive: function () {
          return stateMatchesAny('app.private.contributions.transferList');
        },
      },
      {
        text: gettextCatalog.getString('Donation widget', null, 'Sidebar'),
        classes: 'fa fa-hand-heart',
        url: $state.href('app.private.contributions.widget'),
        isActive: function () {
          return stateMatchesAny('app.private.contributions.widget');
        },
        isShownToUser() {
          return Authorization.hasPackage('widgets');
        },
      },
      {
        text: gettextCatalog.getString('Terminal', null, 'Sidebar'),
        classes: 'fas fa-tablet-android-alt',
        url: $state.href('app.private.contributions.terminals'),
        isActive: function () {
          return stateMatchesAny('app.private.contributions.terminals');
        },
      },
    ],

    forms: [
      {
        text: gettextCatalog.getString('Forms', null, 'Sidebar'),
        classes: 'fa fa-file-alt',
        url: $state.href('app.private.forms.list'),
        isActive: function () {
          return $state.includes('app.private.forms.list');
        },
      },
      {
        text: gettextCatalog.getString('Labels', null, 'Sidebar'),
        classes: 'fa fa-list',
        url: $state.href('app.private.forms.labels'),
        isActive: function () {
          return $state.includes('app.private.forms.labels');
        },
      },
    ],

    intentionen: [
      {
        text: gettextCatalog.getString('Intentions', null, 'Sidebar'),
        classes: 'fa fa-tasks',
        url: $state.href(
          'app.private.intention.overview',
          {},
          { inherit: false }
        ),

        isActive: function () {
          return $state.includes('app.private.intention.overview');
        },
        isShownToUser: function () {
          return true;
        },
      },

      {
        text: gettextCatalog.getString('Stipend', null, 'Sidebar'),
        classes: 'fa fa-book',
        url: $state.href(
          'app.private.intention.stiftungen.overview',
          {},
          { inherit: false }
        ),

        isActive: function () {
          return $state.includes('app.private.intention.stiftungen.overview');
        },
        isShownToUser: function () {
          return true;
        },
      },

      {
        text: gettextCatalog.getString('Legacies', null, 'Sidebar'),
        classes: 'far fa-money-bill-alt',
        url: $state.href(
          'app.private.intention.legate.overview',
          {},
          { inherit: false }
        ),

        isActive: function () {
          return $state.includes('app.private.intention.legate.overview');
        },
        isShownToUser: function () {
          return true;
        },
      },

      {
        text: gettextCatalog.getString('Stoles', null, 'Sidebar'),
        classes: 'fa fa-calendar-alt',
        url: $state.href(
          'app.private.intention.stoles.overview',
          {},
          { inherit: false }
        ),

        isActive: function () {
          return $state.includes('app.private.intention.stoles.overview');
        },
        isShownToUser: function () {
          return Authorization.hasPackage('intentionExtended');
        },
      },
    ],

    portal: [
      {
        text: gettextCatalog.getString('Organization', null, 'Sidebar'),
        classes: 'fa fa-church',
        url: $state.href(
          'app.private.portal.organizations',
          {},
          { inherit: false }
        ),

        isActive: function () {
          return $state.includes('app.private.portal.organizations');
        },
        isShownToUser: function () {
          return true;
        },
      },
      {
        text: gettextCatalog.getString('Safeguarding', null, 'Sidebar'),
        classes: 'fas fa-user-shield',
        url: $state.href(
          'app.private.portal.safeguarding',
          {},
          { inherit: false }
        ),

        isActive: function () {
          return $state.includes('app.private.portal.safeguarding');
        },
        isShownToUser: function () {
          return Authorization.hasPackage('safeguarding');
        },
      },

      {
        text: gettextCatalog.getString('Categories', null, 'Sidebar'),
        classes: 'fa fa-calendar-alt',
        url: $state.href(
          'app.private.portal.categories',
          {},
          { inherit: false }
        ),

        isActive: function () {
          return $state.includes('app.private.portal.categories');
        },
        isShownToUser: function () {
          return true;
        },
      },

      {
        text: gettextCatalog.getString('Plugins', null, 'Sidebar'),
        classes: 'fa fa-tasks-alt',
        url: $state.href('app.private.portal.widgets', {}, { inherit: false }),
        isActive: function () {
          return $state.includes('app.private.portal.widgets');
        },
        isShownToUser: function () {
          return true;
        },
      },

      {
        text: gettextCatalog.getString('Lists', null, 'Sidebar'),
        classes: 'fa fa-file-chart-line',
        url: $state.href(
          'app.private.portal.reports.list',
          {},
          { inherit: false }
        ),

        isActive: function () {
          return $state.includes('app.private.portal.reports');
        },
        isShownToUser: function () {
          return true;
        },
      },
    ],

    homepage: [
      {
        text: gettextCatalog.getString('Website', null, 'Sidebar'),
        classes: 'fa fa-browser',
        url: $state.href('app.private.website.list'),
        isActive: () => $state.includes('app.private.website.list'),
        isShownToUser: () => true,
      },
      {
        text: gettextCatalog.getString('Widgets', null, 'Sidebar'),
        classes: 'fa fa-th-large',
        url: $state.href('app.private.website.widgets'),
        isActive: () => $state.includes('app.private.website.widgets'),
        isShownToUser: () => true,
      },
      {
        text: gettextCatalog.getString('Blogs', null, 'Sidebar'),
        classes: 'fa fa-bullhorn',
        url: $state.href('app.private.website.blogs'),
        isActive: () => $state.includes('app.private.website.blogs'),
        isShownToUser: () => true,
      },
      {
        text: gettextCatalog.getString('Analytics', null, 'Sidebar'),
        classes: 'fa fa-analytics',
        url: $state.href('app.private.website.analytics'),
        isActive: () => $state.includes('app.private.website.analytics'),
        isShownToUser: () => true,
      },
    ],

    calendar: [
      {
        text: gettextCatalog.getString('Calendar', null, 'Sidebar'),
        classes: 'fa fa-calendar-alt',
        url: $state.href('app.private.calendar.full'),
        isActive: function () {
          return $state.includes('app.private.calendar.full');
        },
        isShownToUser: function () {
          return Authorization.hasPermission('canAccessCalendar');
        },
      },

      {
        text: gettextCatalog.getString('My Rotas', null, 'Sidebar'),
        classes: 'fa fa-calendar-check',
        url: $state.href('app.private.calendar.myRotas'),
        isActive: function () {
          return $state.includes('app.private.calendar.myRotas');
        },
        isShownToUser: function () {
          return (
            Authorization.hasPackage('intranet') &&
            Authorization.hasPermission('canAccessPlanning')
          );
        },
      },

      {
        text: gettextCatalog.getString('Rota schemes', null, 'Sidebar'),
        classes: 'far fa-table',
        url: $state.href(
          'app.private.calendar.rotas.schemes',
          {},
          { inherit: false }
        ),

        isActive: function () {
          return $state.includes('app.private.calendar.rotas.schemes');
        },
        isShownToUser: function () {
          return (
            Authorization.hasPackage('intranet') &&
            Authorization.hasPermission('canAccessCalendar')
          );
        },
      },

      {
        text: gettextCatalog.getString('Attendance', null, 'Sidebar'),
        classes: 'fa fa-chart-line',
        url: $state.href(
          'app.private.calendar.attendance',
          {},
          { inherit: false }
        ),

        isActive: function () {
          return $state.includes('app.private.calendar.attendance');
        },
        isShownToUser: function () {
          return Authorization.hasPackage('attendance');
        },
      },

      {
        text: gettextCatalog.getString('Reports', null, 'Sidebar'),
        classes: 'fa fa-list-alt',
        url: $state.href('app.private.calendar.print', {}, { inherit: false }),
        isActive: function () {
          return $state.includes('app.private.calendar.print');
        },
        isShownToUser: function () {
          return Authorization.hasPermission('canAccessCalendar');
        },
      },
      {
        text: gettextCatalog.getString('Collection', null, 'Sidebar'),
        classes: 'fa fa-donate',
        url: $state.href('app.private.calendar.collection'),
        isActive: function () {
          return $state.includes('app.private.calendar.collection');
        },
        isShownToUser: function () {
          return true;
        },
      },

      {
        text: gettextCatalog.getString('Posters & Invites', null, 'Sidebar'),
        classes: 'fa fa-image-polaroid',
        url: $state.href('app.private.calendar.posters'),
        isActive: function () {
          return $state.includes('app.private.calendar.posters');
        },
        isShownToUser: function () {
          return true;
        },
      },
    ],

    intranet: [
      {
        text: gettextCatalog.getString('Groups', null, 'Sidebar'),
        classes: 'fa fa-users',
        url: $state.href('app.private.intranet.groups'),
        isActive: function () {
          return (
            $state.includes('app.private.intranet.groups') ||
            $state.includes('app.private.intranet.group')
          );
        },
        isShownToUser: function () {
          return true;
        },
      },

      {
        text: gettextCatalog.getString('Files', null, 'Sidebar'),
        classes: 'fa fa-folder-open',
        url: $state.href('app.private.intranet.files'),
        isActive: function () {
          return $state.includes('app.private.intranet.files');
        },
        isShownToUser: function () {
          return Authorization.hasPackage('intranet');
        },
      },

      {
        text: gettextCatalog.getString('Blogs', null, 'Sidebar'),
        classes: 'fa fa-bullhorn',
        url: $state.href('app.private.intranet.blogs'),
        isActive: function () {
          return $state.includes('app.private.intranet.blogs');
        },
        isShownToUser: function () {
          return Authorization.hasPackage('intranet');
        },
      },

      {
        text: gettextCatalog.getString('Churchscreen', null, 'Sidebar'),
        classes: 'fa fa-film',
        url: $state.href('app.private.intranet.churchscreen'),
        isActive: function () {
          return $state.includes('app.private.intranet.churchscreen');
        },
        isShownToUser: function () {
          return (
            Authorization.hasPackage('churchscreen') &&
            Authorization.hasPermission('canAccessChurchScreen')
          );
        },
      },
    ],

    settings: [
      {
        text: gettextCatalog.getString('Users', null, 'Sidebar'),
        classes: 'fa fa-address-book',
        url: $state.href('app.private.settings.users'),
        isActive: () => $state.includes('app.private.settings.users'),
        isShownToUser: () => Authorization.hasPermission('canAdministerUsers'),
      },

      {
        text: gettextCatalog.getString('Roles and access', null, 'Sidebar'),
        classes: 'fa fa-key',
        url: $state.href('app.private.settings.roles'),
        isActive: () => $state.includes('app.private.settings.roles'),
        isShownToUser: () => Authorization.hasPermission('canAdministerUsers'),
      },

      {
        text: gettextCatalog.getString('Categories'),
        classes: 'fa fa-calendar-alt',
        url: $state.href('app.private.settings.calendar', { type: 'event' }),
        isActive: function () {
          return $state.includes('app.private.settings.calendar');
        },
        isShownToUser: function () {
          return (
            Authorization.hasPermission('canAccessCalendar') &&
            Authorization.hasPackage('calendar')
          );
        },
      },

      {
        text: gettextCatalog.getString('Parish & Resources'),
        classes: 'fa fa-church',
        url: $state.href('app.private.settings.resources'),
        isActive: function () {
          return $state.includes('app.private.settings.resources');
        },
        isShownToUser: function () {
          return (
            Authorization.hasPermission('canAccessCalendar') &&
            (Authorization.hasPackage('calendar') ||
              Authorization.hasPackage('people') ||
              Authorization.hasPackage('forms') ||
              Authorization.hasPackage('multiParish') ||
              Authorization.hasPackage('widgets'))
          );
        },
      },

      {
        text: gettextCatalog.getString('People', null, 'Sidebar'),
        classes: 'fa fa-users',
        url: $state.href('app.private.settings.people'),
        isActive: () => $state.includes('app.private.settings.people'),
        isShownToUser() {
          return (
            Authorization.hasPermission('canAccessPeople') &&
            Authorization.hasPackage('people')
          );
        },
      },
      {
        text: gettextCatalog.getString('Other', null, 'Sidebar'),
        classes: 'fa fa-cog',
        url: $state.href('app.private.settings.other'),
        isActive: () => $state.includes('app.private.settings.other'),
      },

      {
        text: gettextCatalog.getString('Legal', null, 'Sidebar'),
        classes: 'fa fa-book',
        url: $state.href('app.private.settings.legal'),
        isActive: () => $state.includes('app.private.settings.legal'),
        isShownToUser: () => Authorization.hasPermission('canAdministerUsers'),
      },

      {
        text: gettextCatalog.getString('Billing', null, 'Sidebar'),
        classes: 'fa fa-credit-card',
        url: $state.href('app.private.settings.account'),
        isActive: () => $state.includes('app.private.settings.account'),
        isShownToUser: () => Authorization.hasPermission('canAdministerUsers'),
      },

      {
        text: gettextCatalog.getString('Payments', null, 'Sidebar'),
        classes: 'far fa-money-bill-alt',
        url: $state.href('app.private.settings.mvrForm'),
        isActive: () => $state.includes('app.private.settings.mvrForm'),
        isShownToUser: () =>
          Authorization.hasPermission('canAdministerUsers') &&
          Authorization.hasPackage('contributions'),
      },
      {
        text: gettextCatalog.getString('Privacy policy', null, 'Sidebar'),
        classes: 'far fa-shield-alt',
        url: $state.href('app.private.settings.privacyPolicy'),
        isActive: () => $state.includes('app.private.settings.privacyPolicy'),
        isShownToUser: () =>
          Authorization.hasPermission('canAdministerUsers') &&
          (Authorization.hasPackage('people') ||
            Authorization.hasPackage('forms') ||
            Authorization.hasPackage('homepage') ||
            Authorization.hasPackage('contributions')),
      },
      {
        text: gettextCatalog.getString('Imprint', null, 'Sidebar'),
        classes: 'far fa-info-square',
        url: $state.href('app.private.settings.imprint'),
        isActive: () => $state.includes('app.private.settings.imprint'),
        isShownToUser: () =>
          cdApp.organization.locale.language === 'de' &&
          Authorization.hasPermission('canAdministerUsers'),
      },

      {
        text: gettextCatalog.getString('Intentions Fees'),
        classes: 'fa fa-euro-sign',
        url: $state.href(
          'app.private.settings.fees.overview',
          {},
          { inherit: false }
        ),

        isActive: function () {
          return $state.includes('app.private.settings.fees.overview');
        },
        isShownToUser: function () {
          return Authorization.hasPackage('intentionSimple');
        },
      },

      {
        text: gettextCatalog.getString('Report templates', null, 'Sidebar'),
        classes: 'fa fa-file-code',
        url: $state.href(
          'app.private.settings.reportTemplates.overview',
          {},
          { inherit: false }
        ),

        isActive: function () {
          return $state.includes(
            'app.private.settings.reportTemplates.overview'
          );
        },
        isShownToUser: function () {
          return false; // Temporarily hide until fully confirmed that we will not be allowing custom templates anymore. Must also remove code associated to this page when that happens
        },
      },
    ],

    safeguarding: [
      {
        text: gettextCatalog.getString('Safeguarding', null, 'Sidebar'),
        classes: 'fas fa-user-shield',
        url: $state.href('app.private.safeguarding.list'),
        isActive: () => $state.includes('app.private.safeguarding.list'),
        isShownToUser: () => true,
      },
    ],
  };

  $ctrl.sidebarItems = getSidebarItems();

  FeatureToggleService.hasFeature('collection').then((hasFeature) => {
    items.calendar.find(
      ({ url }) => url === $state.href('app.private.calendar.collection')
    ).isShownToUser = () => hasFeature;
  });
  FeatureToggleService.hasFeature('anniversary_app').then((hasFeature) => {
    items.people.find(
      ({ url }) => url === $state.href('app.private.people.anniversary')
    ).isShownToUser = () => hasFeature;
  });

  /**
   * Return the sidebar menu links based on the current module
   *
   * @returns {Array}
   */
  function getSidebarItems() {
    if ($state.includes('app.private.intranet')) {
      return items.intranet;
    } else if ($state.includes('app.private.calendar')) {
      return items.calendar;
    } else if ($state.includes('app.private.forms')) {
      return items.forms;
    } else if ($state.includes('app.private.people')) {
      return items.people;
    } else if ($state.includes('app.private.contributions')) {
      return items.contributions;
    } else if ($state.includes('app.private.settings')) {
      return items.settings;
    } else if ($state.includes('app.private.intention')) {
      return items.intentionen;
    } else if ($state.includes('app.private.portal')) {
      return items.portal;
    } else if ($state.includes('app.private.website')) {
      return items.homepage;
    } else if ($state.includes('app.private.safeguarding')) {
      return items.safeguarding;
    } else {
      return [];
    }
  }
}

angular.module('cdApp.shared').component('cdAppNav', {
  templateUrl: '@/app/shared/components/app-nav/app-nav.component.html',
  controller: AppNavController,
});
