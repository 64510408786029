import IntlTelInput from 'react-intl-tel-input';
import { Organization } from '@/react/shared/models/organization';

export {};

declare global {
  interface Window {
    cdApp: CdApp;
    cdUseLandingPage: boolean;
    cdSiteDomain: string;
    intlTelInputUtils?: IntlTelInput.Static;
  }
}

declare interface Config {
  api: {
    forms: string;
    main: string;
    partners: string;
    payments: string;
    portal: string;
  };
  endpoint: string;
  environment: string;
}

interface Locale {
  country: string;
  language: string;
  timezone: string;
}
declare interface Me {
  adminInAnyGroupAcrossAllOrgs: boolean;
  altarPermissions: string[];
  email: string;
  firstName: string;
  fullName: string;
  helpDeskUrl: string;
  id: number;
  intercomHash: string;
  lastName: string;
  isChurchDeskEmployee: boolean;
  masquerading: boolean;
  masqueradingUser: string;
  locale: Locale;
  picture?: {
    id: number;
    url: string;
  };
}

declare interface References {
  appUrl: string;
  altar: string;
  formViewer: string;
  portal: string;
  widgetsUrl: string;
  websiteWidgetsUrl: string;
  paymentsFrontend: string;
  donateFrontend: string;
  donationLandingPage: string;
  landingPage: boolean;
  privacyPolicyLandingPage: string;
  imprintLandingPage: string;
  helpscout: {
    da: string;
    en: string;
    de: string;
  };
  stripe: {
    subscriptions: string;
    contributions: string;
  };
  s3: string;
  googleMaps: string;
}

export declare interface CdApp {
  data: any;
  config: Config;
  references: References;
  me: Me;
  version: string;
  release: string;
  organization: Pick<
    Organization,
    | 'id'
    | 'name'
    | 'siteStatus'
    | 'installationUrl'
    | 'aegirInstallationUrl'
    | 'countryIso2'
    | 'planId'
    | 'myGroups'
    | 'user_organization'
    | 'locale'
    | 'modules'
    | 'packages'
    | 'permissions'
    | 'email'
    | 'stripeCustomerId'
    | 'settings'
    | 'churches'
    | 'facebook'
    | 'currencyIso'
    | 'currency'
    | 'categories'
    | 'resources'
    | 'dioceseId'
  >;
  showChurchSelector: boolean;
}

const cdApp: CdApp = window.cdApp;

export default cdApp;
