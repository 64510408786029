import React from 'react';
import { Select } from 'antd';

import { gettextCatalog } from '../../../services/I18nService';
import { AnniversaryInterface } from '../../../calendar/types/anniversaryTypes';
import useAnniversary from '../hooks/useAnniversary';
import { GetPeopleAnniversaryList } from '../store/anniversary';

import { anniversaryColumns } from './AnniversaryTableColumn';

import CdClientSideTable from '@/react/shared/components/cd-client-side-table/CdClientSideTable';

export const Anniversary = () => {
  const { setAnniversaryType, anniversaryTypes, anniversaryType } =
    useAnniversary();
  return (
    <CdClientSideTable<AnniversaryInterface>
      searchParams={{ type: anniversaryType }}
      querySource={GetPeopleAnniversaryList}
      columns={anniversaryColumns()}
      emptyStateProps={{
        title: gettextCatalog.getString('No upcoming anniversary'),
      }}
      filterForm={
        <Select
          defaultValue="birthday"
          style={{ width: 200 }}
          onChange={setAnniversaryType}
          options={anniversaryTypes}
        />
      }
    />
  );
};
