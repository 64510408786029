import { Space, SpaceProps } from 'antd';
import React from 'react';

export const CdVerticalSpace = (
  props: Pick<SpaceProps, 'size' | 'children'>
) => (
  <Space direction="vertical" style={{ width: '100%' }} size={props.size}>
    {props.children}
  </Space>
);
