import React, { useEffect, useState } from 'react';
import { RecoilValue, useRecoilValueLoadable } from 'recoil';

import CdTable, { CdTableProps, useCdTable } from '../cd-table';
import { OrderFunctions } from '../cd-table/useCdTable';

interface CdSimpleTableProps<T>
  extends Pick<
    CdTableProps<T>,
    | 'noStyle'
    | 'pageSize'
    | 'columns'
    | 'emptyStateProps'
    | 'actionButtons'
    | 'columnSelectorKey'
    | 'headingText'
    | 'filterForm'
    | 'rowSelection'
  > {
  recoilSource: RecoilValue<{ items: T[]; total: number }>;
  orderFunctions?: OrderFunctions<T>;
  filters?: ((item: T, index: number) => boolean)[];
  resetPaginationOnDataChange?: boolean;
}

function CdSimpleTable<T extends Record<string, any> = any>(
  props: CdSimpleTableProps<T>
) {
  const {
    resetPaginationOnDataChange = true,
    recoilSource,
    filters,
    orderFunctions,
    pageSize,
    ...rest
  } = props;

  const { data, handleTableChange } = useCdTable<T>({
    recoilSource,
    orderFunctions,
    defaultPageSize: pageSize,
    filters,
  });
  const [pageNumber, setPageNumber] = useState<number>(1);
  const recoilSourceLoadable = useRecoilValueLoadable(recoilSource);
  const isLoading = recoilSourceLoadable.state === 'loading';
  useEffect(() => {
    if (resetPaginationOnDataChange) {
      setPageNumber(1);
      handleTableChange(1, pageSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recoilSourceLoadable.contents]);
  return (
    <CdTable<T>
      {...rest}
      onTableChange={handleTableChange}
      page={[pageNumber, setPageNumber]}
      data={data}
      isLoading={isLoading}
      pageSize={pageSize}
    />
  );
}

export default CdSimpleTable;
