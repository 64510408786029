import { Tabs, Space } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { CdCircleNumber } from '../Icons';

export const CdTabs = styled(Tabs)<{
  $fluidHeight?: boolean;
  $noInnerPadding?: boolean;
}>`
  &&&& {
    flex: 1 1;
    overflow: ${({ $fluidHeight }) => ($fluidHeight ? 'auto' : 'hidden')};
    height: ${({ $fluidHeight }) => ($fluidHeight ? '100%' : 'auto')};

    .ant-tabs-content {
      height: ${({ $fluidHeight }) => ($fluidHeight ? '100%' : 'auto')};
    }
    .ant-tabs-nav-list {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    .ant-tabs-tab {
      width: 100%;
    }
    .ant-tabs-tab:first-child {
      width: 99%;
    }
    .ant-tabs-tabpane {
      height: ${({ $fluidHeight }) => ($fluidHeight ? '100%' : 'auto')};
    }
    .ant-tabs-extra-content {
      margin-left: 16px;
    }
    .ant-card-body {
      padding: ${({ $noInnerPadding }) => $noInnerPadding && '0'};
    }
  }
`;

CdTabs.defaultProps = {
  size: 'large',
  type: 'card',
  tabBarStyle: { marginBottom: 0 },
};

export const CdTabPaneTab = ({
  tabNumber,
  title,
  subTitle,
}: {
  tabNumber?: number;
  title: string;
  subTitle?: string;
}) => (
  <Space style={{ margin: '8px' }} align="center">
    {tabNumber && <CdCircleNumber label={tabNumber} />}
    <div>
      <div style={{ fontWeight: 'bold', fontSize: '16px' }}>{title}</div>
      {subTitle && (
        <div style={{ fontSize: '13px', whiteSpace: 'normal' }}>{subTitle}</div>
      )}
    </div>
  </Space>
);
