import React, { Suspense, useEffect, useState } from 'react';
import { Form, Layout, Card, Row, Col, Spin } from 'antd';

import {
  CdTabPaneTab,
  CdTabs,
} from '../../../shared/components/tab-container/TabContainer';
import { gettextCatalog } from '../../../services/I18nService';
import Redactor from '../shared/containers/Redactor';
import MessageDetails from '../shared/containers/MessageDetails';
import UploadAttachment from '../shared/containers/UploadAttachment';
import NavigationButtons from '../advanced-editor/NavigationButtons';
import { useMessage, useMailEditor } from '../../hooks';
import {
  MessageStatisticsFilters,
  MessageType,
  ParentType,
} from '../../types/message';
import { Statistics } from '../statistics/Statistics';

import { CdNotFound } from '@/react/shared/components/cd-not-found/CdNotFound';

export default function MailEditor({
  messageId,
  to,
  contactIds,
  statisticsFilter,
  sourceInfo,
}: {
  messageId?: number;
  to: any[];
  contactIds: number[];
  statisticsFilter: MessageStatisticsFilters;
  sourceInfo?: any;
}) {
  const [form] = Form.useForm();
  const [current, setCurrent] = useState('design');
  const {
    message,
    saveMessage,
    deleteMessage,
    cloneMessage,
    sendMessage,
    sendTestMessage,
    deleteFile,
    attachFile,
    refetchMessage,
  } = useMessage(messageId, MessageType.BASIC, sourceInfo);

  const { debouncedOnFormChange } = useMailEditor({
    type: MessageType.BASIC,
    form,
    message,
    saveMessage,
    to,
    contactIds,
  });

  const isScheduled = message?.scheduled;
  const isSent = message?.sent;
  const parentType =
    to?.[0]?.anniversary || message?.anniversary
      ? ParentType.ANNIVERSARY
      : ParentType.MESSAGE;
  useEffect(() => {
    if (isScheduled) setCurrent('statistics');
  }, [isScheduled]);

  const onChange = (current) => {
    setCurrent(current);
  };

  if (!message?.id && messageId) {
    return (
      <CdNotFound
        title={gettextCatalog.getString('Not found')}
        subTitle={gettextCatalog.getString('The message could not be found.')}
      />
    );
  }
  const tabItems = [
    {
      key: 'design',
      label: (
        <CdTabPaneTab
          title={
            !isSent
              ? gettextCatalog.getString('Create message')
              : gettextCatalog.getString('Message preview')
          }
          subTitle={
            !isSent
              ? gettextCatalog.getString('Write your message and add content')
              : gettextCatalog.getString('View the message you sent')
          }
        />
      ),
      children: (
        <Card
          bodyStyle={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
          }}
          style={{ height: '100%' }}
        >
          <Row justify="center">
            <Col style={{ maxWidth: '800px' }} flex="1 1 auto">
              <MessageDetails isSent={!!isSent} />
              {!isSent ? (
                <Form.Item
                  name={['content', 'body']}
                  initialValue={message?.content.body}
                  label={<span style={{ display: 'none' }}></span>}
                  colon={false}
                >
                  <Redactor
                    disabled={!!isSent}
                    imagePluginEnabled={false}
                    parentType={parentType}
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  name={['rendered']}
                  initialValue={message?.content.body}
                  label={<span style={{ display: 'none' }}></span>}
                  colon={false}
                >
                  <Redactor
                    disabled={!!isSent}
                    imagePluginEnabled={false}
                    parentType={parentType}
                  />
                </Form.Item>
              )}
              <Form.Item
                label={<span style={{ display: 'none' }}></span>}
                colon={false}
                name="files"
                // validateTrigger needs to be null to ensure that the below
                // validator rule is only triggered when form.validateFields() is used.
                validateTrigger={null}
                rules={[
                  {
                    validator: (rule, value) => {
                      let error;
                      value?.forEach((file) => {
                        if (file.status === 'error') {
                          error = new Error(
                            gettextCatalog.getString(
                              'One or more files has been rejected.'
                            )
                          );
                        }
                        if (file.status === 'uploading') {
                          error = new Error(
                            gettextCatalog.getString(
                              'One or more files is still uploading. Please wait for them to upload first.'
                            )
                          );
                        }
                      });
                      if (error) return Promise.reject(error);
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <UploadAttachment
                  disabled={!!isSent || !messageId}
                  disabledMessage={
                    !messageId
                      ? gettextCatalog.getString(
                          'Please fill in the message details before adding files to the message'
                        )
                      : gettextCatalog.getString(
                          'Files cannot be added since the message is scheduled for sending.'
                        )
                  }
                  uploadFile={attachFile}
                  deleteFile={deleteFile}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      ),
    },
  ];

  const statisticTab = {
    key: 'statistics',
    label: (
      <CdTabPaneTab
        title={gettextCatalog.getString('Statistics')}
        subTitle={gettextCatalog.getString(
          'Analyze the performance of your message'
        )}
      />
    ),
    children: (
      <Card style={{ width: '100%' }}>
        <Suspense
          fallback={
            <div style={{ textAlign: 'center' }}>
              <Spin />
            </div>
          }
        >
          <Statistics
            message={message}
            refetchMessage={refetchMessage}
            defaultFilter={statisticsFilter}
          />
        </Suspense>
      </Card>
    ),
  };
  if (isScheduled) tabItems.push(statisticTab);

  return (
    <Layout.Content className="app-main" style={{ padding: 0 }}>
      <Form
        form={form}
        labelCol={{ lg: { span: 3 } }}
        style={{ height: '100%' }}
        onValuesChange={debouncedOnFormChange}
      >
        <CdTabs
          activeKey={current}
          onChange={onChange}
          style={{ overflow: 'auto' }}
          tabBarExtraContent={{
            right: (
              <NavigationButtons
                message={message}
                handleCopy={cloneMessage}
                handleDelete={deleteMessage}
                sendMessage={sendMessage}
                sendTestMessage={sendTestMessage}
                form={form}
                saveMessage={saveMessage}
                formValidationError={() => setCurrent('design')}
                debouncedOnFormChange={debouncedOnFormChange}
              />
            ),
          }}
          items={tabItems}
        />
      </Form>
    </Layout.Content>
  );
}
