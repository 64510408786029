import React from 'react';
import { Button } from 'antd';

import { gettextCatalog } from '../../services/I18nService';
import { CdPage } from '../../shared/components/cd-page/CdPage';
import { Anniversary } from '../anniversary/components/Anniversary';

import { getStateUrl } from '@/react/services/StateServiceFactory';

export default function AnniversaryPage() {
  return (
    <CdPage
      pageHeaderProps={{
        extra: [
          <Button href={getStateUrl(`app.private.settings.people`)}>
            {gettextCatalog.getString('Settings')}
          </Button>,
        ],
        title: gettextCatalog.getString('Anniversaries'),
        subTitle: gettextCatalog.getString(
          'View all upcoming anniversaries for next 14 days'
        ),
        backIcon: false,
      }}
    >
      <Anniversary />
    </CdPage>
  );
}
