import React from 'react';
import { Checkbox, Form, Popover, Space, Spin, Typography } from 'antd';

import { gettextCatalog } from '../../../services/I18nService';
import { useAnniversarySettings } from '../hooks/useAnniversary';

import { AnniversaryNotifyUsers } from './AnniversaryNotifyUsers';

import { CdAlertInfo } from '@/react/shared/components/Icons';

const { Title, Text } = Typography;

export const AnniversarySettings = () => {
  const { anniversaryTypes, loading } = useAnniversarySettings();

  return (
    <Spin spinning={loading}>
      <Space direction="vertical">
        <Typography>
          <Title level={4}>
            {gettextCatalog.getString(
              'Specify anniversaries to include in the anniversary app'
            )}
          </Title>
          <Text>
            {gettextCatalog.getString(
              'Please select which anniversaries or other important events to work with in the anniversary app. You can select from all data based people contact fields. The anniversary types you select here will be available in the anniversary and you will receive a notification when a special anniversary is coming up 14 days prior.'
            )}
          </Text>
        </Typography>

        {anniversaryTypes?.map((type) => (
          <Space key={type.value}>
            <Form.Item
              name={['anniversariesTypeSetting', type.value, 'checked']}
              style={{ margin: '0px' }}
              valuePropName="checked"
            >
              <Checkbox style={{ fontWeight: 'normal' }}>{type.label}</Checkbox>
            </Form.Item>
            {type.specialDaysList.length > 0 && (
              <Popover
                content={type.specialDaysList.join(', ')}
                title={gettextCatalog.getString('Special days')}
                trigger="hover"
                placement="right"
              >
                <div>
                  <CdAlertInfo style={{ cursor: 'pointer' }} />
                </div>
              </Popover>
            )}
          </Space>
        ))}

        <Typography>
          <Title level={4}>
            {gettextCatalog.getString(
              'Select which users should receive a notification'
            )}
          </Title>
          <Text>
            {gettextCatalog.getString(
              'You can select one or more users who 14 days prior will be informed about special upcoming anniversaries'
            )}
          </Text>
        </Typography>
        <Form.Item name="usersToNotify">
          <AnniversaryNotifyUsers />
        </Form.Item>
      </Space>
    </Spin>
  );
};
